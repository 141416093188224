import {
  MediaItem,
  Image,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { imageDataToMediaUrl } from '@wix/challenges-web-library';

const fixThumbnailFromMobile = (thumbnail) => {
  if (thumbnail?.url && !thumbnail.url.includes('media/')) {
    thumbnail.url = `media/${thumbnail.url}`;
  }
};

export const getMediaPreview = (
  media: MediaItem,
  width = 300,
  height = 200,
  quality = 100,
  format = 'wepb',
) => {
  fixThumbnailFromMobile(media?.video?.thumbnail);

  return media && (media.image || media.video)
    ? imageDataToMediaUrl({
        ...(media.video ? media.video.thumbnail : (media.image as any)),
        width,
        height,
        quality,
        format,
      })
    : null;
};

export const getMediaImagePreview = (
  thumbnail: Image,
  width = 300,
  height = 200,
  quality = 100,
) => {
  fixThumbnailFromMobile(thumbnail);

  return thumbnail
    ? imageDataToMediaUrl({
        ...(thumbnail as any),
        width,
        height,
        quality,
      })
    : null;
};

export const getVideoFullUrl = (url: string): string => {
  return (
    'https://video.wixstatic.com/' +
    (url?.split('/').length > 2 ? url : `${url}/720p/mp4/file.mp4`)
  );
};

export const getVideoTypeFromUrl = (url: string): string => {
  return `video/${url.split('.').pop()}`;
};
